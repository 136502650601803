$(document).on("scroll", function() {
    var scrollTop = $(window).scrollTop();
    var scrollBottom = scrollTop + $(window).height();

    var pageBottom = $(window).height();

    var diff = pageBottom - scrollBottom;

    var fadeSpeed = ($(window).height() > 750) ? 200 : 100;

    var opacity = 1 + diff / fadeSpeed;

    $('.fade-scroll').each(function() {
        $(this).css("opacity", opacity);
    });
});

$("#scrollButton").click(function() {
    $([document.documentElement, document.body]).animate({
        scrollTop: $("#section").offset().top - 100
    }, 1000);
});

ScrollReveal().reveal('.scrollReveal', { easing: 'ease-in', duration: 500 });
